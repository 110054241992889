import axios from 'axios';

import {
  MyAccountService,
  SentryService,
  NavigationService,
  NotificationService,
  KonakartService,
  useSubscribedPackages,
  STAFF_ACCOUNT_TYPE,
  updateStaffAccountQuantity,
  MyAccountSWR,
  getTreatment,
  SPLITIO_KEY,
} from '@sky-tv-group/shared';

import { getClient } from '../store/authStore/authStore';
import {
  ACCOUNT_API,
  SENTRY_DSN,
  NAVIGATION_URL,
  KONAKART_ENDPOINT,
  APP_ENVIRONMENT,
  SENTRY_DENY_URLS,
} from '../config';
import useSWR from 'swr';
import { gqlAgentMyAccount } from './agent';
import { searchResults } from '../graphQL/queries/searchResults';
import { getsearch } from '../graphQL/queries/getsearch';

const sentryService = new SentryService(SENTRY_DSN, APP_ENVIRONMENT, SENTRY_DENY_URLS);

const agentMyAccount = axios.create({
  baseURL: ACCOUNT_API,
});

const agentNavigation = axios.create({
  baseURL: NAVIGATION_URL,
});


const agentKK = axios.create({
  baseURL: KONAKART_ENDPOINT,
});

const kkService = new KonakartService(agentKK);

const navService = new NavigationService(agentNavigation);

agentMyAccount.interceptors.request.use(async config => {
  const accessToken = await getClient()?.getTokenSilently();
  config.headers['authorization'] = `Bearer ${accessToken}`;
  return config;
});

let myAccountService = new MyAccountService(agentMyAccount);

export const myAccountSWR = new MyAccountSWR(myAccountService, kkService);

const { getSubscribedPackages } = useSubscribedPackages();

// const getEvents = async (start: number, end: number, limit: number, channel?: number) => {
//   const params = {
//     start,
//     end,
//     limit,
//     ...(channel && { channelNumber: channel }),
//   };

//   // console.log('EPG_SEARCH_API', EPG_SEARCH_API, params);
//   const { data } = await axios.get<{ events: T_EPG_Event[] }>(EPG_SEARCH_API, {
//     params,
//   });

//   return data.events.map(event => {
//     event.startAt = Number(event.start);
//     event.endAt = Number(event.end);
//     return event;
//   });
// };
export const fetchSearchDropDownResults = async (query: string) => {
  try {
    const res = await gqlAgentMyAccount.query({
      query: getsearch,
      variables: { term: query },
    });
    let results = res.data?.experience?.search?.results;
    return results;
  } catch (err) {
    // console.log('search error==', err);
  }
};

export const fetchSearchResults = async (query: string, after: string) => {
  try {
    const currentYear = new Date().getFullYear();
    const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0');
    let date = new Date()
      .getDate()
      .toString()
      .padStart(2, '0');
    let fromDate = `${currentYear}-${currentMonth}-${date}T00:00:00Z`;
    const res = await gqlAgentMyAccount.query({
      query: searchResults,
      variables: { term: query, from: fromDate, after: after },
    });
    let results = res.data?.experience?.search?.slotResultsPage;
    // let eventsArr: any[] = [];
    // events.map(item => {
    //   eventsArr.push({
    //     title: item.title,
    //   });
    // });
    return results;
  } catch (err) {
    // console.log('search error==', err);
  }
};

// export const useSearchResult = async (query: string) => {
//   return useSWR(query, async () =>
//     (await axios.get<T_EPG_SearchResult[]>(`${EPG_SEARCH_API}/search/cat`, { params: { text: query } })).data
//       .flatMap(r => r.events)
//       .map(event => {
//         event.startAt = Number(event.start);
//         event.endAt = Number(event.end);
//         event.relatedEvents = event.relatedEvents.map(e => {
//           e.startAt = Number(e.start);
//           e.endAt = Number(e.end);
//           return e;
//         });
//         return event;
//       })
//   );
// };

// const getChannels = async () => {
//   const { data } = await axios.get<T_EPG_Channel[]>(EPG_CHANNELS_API);
//   return data;
// };

const getBoxes = myAccountService.getBoxes;

const getProfile = myAccountService.getProfile;

// const remoteRecord = async (payload: T_EPG_Remote_Record) => {
//   return myAccountService.remoteRecord(payload);
// };

const notificationService = new NotificationService(agentMyAccount);

const getProducts = kkService.getAllProduct;

/**
 * Billing logic to add quantity to staff accounts as they have free quantity as positive,
 * but paid quantity as 0. This prevents staff from recording as it does not show items in their
 * paid subscription.
 * Manually sets quantity of some products to 1 for active cable service occurrences
 */
const getBillingWithStaffLogic = async () => {
  let customer = await myAccountService.getCustomer();
  let billing = await myAccountService.getBilling();
  let staffAccount = customer?.type === STAFF_ACCOUNT_TYPE ? true : false;
  if (billing?.services?.CableServiceOccurrence) {
    billing.services.CableServiceOccurrence = updateStaffAccountQuantity(
      staffAccount,
      billing.services.CableServiceOccurrence
    );
  }
  return billing;
};

const getBilling = getBillingWithStaffLogic;

/**
 * get the user's subscription packages
 * return the user subscription's list of packages.
 */
const getPackagesSubscription = async () => {
  let overrideProductInfoFromKK = await getTreatment(SPLITIO_KEY.SKYWEB_OVERRIDE_PRODUCT_INFO_FROM_KONAKART);
  let billing = await getBilling();
  let products = await getProducts(overrideProductInfoFromKK ? true : false);
  return await getSubscribedPackages(products, billing);
};

export {
  sentryService,
  navService,
  notificationService,
  // getChannels,
  // getEvents,
  getBoxes,
  getProfile,
  // remoteRecord,
  getPackagesSubscription,
  getProducts,
  myAccountService,
};
